:root {
  --primary-color: #379b3b;
  --secondary-color: #b50000;
  --text-color: #333;
  --font-size: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: center;
}

.button {
  margin: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  background-color: var(--primary-color);
  color: white;
  font-size: var(--font-size);
  cursor: pointer;
}

.button:hover {
  background-color: var(--secondary-color);
}

textarea {
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  font-size: var(--font-size);
  font-family: 'Fira Code';
  max-width: 80%;
}

@media (max-width: 1080px) {
  textarea {
      width: calc(100vw - 4rem);
      max-width: none;
  }
}

.message {
  color: var(--primary-color);
  font-size: var(--font-size);
}

a {
  text-decoration: none;
  color: var(--secondary-color);
}

::selection {
  background-color: var(--secondary-color);
  color: var(--text-color);
}
button.backup {
margin-left: 10px;
background-color: #f2f2f2;
border: none;
color: #333;
padding: 5px 10px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 14px;
cursor: pointer;
border-radius: 3px;
}

button.backup:hover {
background-color: #e6e6e6;
}